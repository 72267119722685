<template>
  <div>
  </div>
</template>
<script>
import util from '@/util/util';
export default {
  components: {},
  data () {
    return {
      myTable8: 0,
    };
  },

  async beforeRouteEnter (to, from, next) {
    let id = to.params['row']['businessKey']
    next(async vm => {
      let result = await vm.backFillEditData(id)
      let row = JSON.stringify(to.params.row);
      if (result.data.entity.pkHolidayChange !== null && result.data.entity.pkHolidayChange !== '') {
        let changeUrl = 'tbm/applyHoliday/applyHolidayChangeView'
        if (to.params.pageType == 'edit') {
          changeUrl = 'tbm/applyHoliday/applyHolidayChange'
        }

        vm.$router.replace({
          name: changeUrl,
          query: {
            businessKey: to.query.businessKey,
            type: to.query.type,
            row: row,
            pkParent: result.data.entity.pkHolidayChange
          },
          params: {
            pageType: to.params.pageType,
          },
        }, () => {
          // util.closeCurrentTag('tbm/applyHoliday/applyHolidayViewTabs', vm);
        });
      } else {
        let editUrl = 'tbm/applyHoliday/applyHolidayApplyView'
        if (to.params.pageType == 'edit') {
          editUrl = 'tbm/applyHoliday/applyHolidayEdit';
        }
        vm.$router.replace({
          name: editUrl,
          query: {
            businessKey: to.query.businessKey,
            type: to.query.type,
            row: row
          },
          params: {
            pageType: to.params.pageType,
          },
        }, () => {
          // util.closeCurrentTag('tbm/applyHoliday/applyHolidayViewTabs', vm);
        });
      }
    })


  },
  methods: {
    backFillEditData (id) {
      return this.$myHttp({
        method: 'POST',
        url: '/hrtbm/applyHoliday/view',
        data: {
          'entity': {
            'id': id
          }
        }
      })
    },


    handleClick (tab) {
    },
    getTotal (argName, total) {
      this[argName] = total;
    }
  },
  mounted: function () {
  },
  activated: function () {
  },
  created: function () {
  },
  filters: {}
};
</script>
<style scoped>
.myElTabs {
  height: 100%;
  margin-left: 20px;
}
</style>